import { APP_API_URI } from 'lib/constants';
import { GET, POST } from 'lib/api';

export const fetchIngredient = async ({
  swapOutIngredientId,
  showLiveMeals,
  showUnassignedMeals,
}) => {
  const response = await GET(`${APP_API_URI}/internal/culinary/ingredient_swapper/ingredient`, {
    params: {
      ingredient_id: swapOutIngredientId,
      show_live_meals: showLiveMeals,
      show_unassigned_meals: showUnassignedMeals,
    },
  });

  return response.data;
};

export const swapIngredient = async ({ swapOutIngredientAssignment, swapInIngredient }) => {
  const url = `${APP_API_URI}/internal/culinary/ingredient_swapper/swap_ingredient`;

  const response = await POST(url, {
    display_name: swapInIngredient.displayName,
    ingredient_packaging_id: swapInIngredient.ingredientPackagingId,
    measure: swapInIngredient.measure,
    portion_method: swapInIngredient.portionMethod,
    quantity: swapInIngredient.quantity,
    standard_unit: swapInIngredient.standardUnit,
    swap_in_ingredient_id: swapInIngredient.id,
    swap_out_ingredient_assignment_id: swapOutIngredientAssignment.id,
    usage_yield: swapInIngredient.usageYield,
  });

  return response.data;
};
