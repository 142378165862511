// Dependencies
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';

// Components
import { Loading } from 'components/shared';
import useAllowed from 'lib/useAllowed';
import ConfirmReplacementsDialog from 'components/IngredientSwapper/ConfirmReplacementsDialog/ConfirmReplacementsDialog';

const MealRow = ({
  swapInIngredient,
  swapOutIngredientAssignment,
  onPostSwapIngredient,
  classes,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const canSwapIngredients = useAllowed('swapIngredients');

  // Helpers
  const handleSwapIngredient = () => {
    onPostSwapIngredient(swapOutIngredientAssignment, swapInIngredient);
  };

  const handleSwapWithReplacements = () => {
    handleSwapIngredient();
    setOpenDialog(false);
  };

  const handleConfirmReplacements = () => {
    if (swapOutIngredientAssignment.hasReplacements) {
      setOpenDialog(true);
    } else {
      handleSwapIngredient();
    }
  };

  const failedSwap = swapOutIngredientAssignment.swapStatus === 'failed swap';

  // Rendering
  const renderConceptIdLink = () => {
    return (
      <a
        href={swapOutIngredientAssignment.adminLink}
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {swapOutIngredientAssignment.culinaryVersionId}
      </a>
    );
  };

  const renderMenusList = () => {
    return (
      <ul className={classes.linkList}>
        {swapOutIngredientAssignment.menus.map((menu) => {
          return (
            <li key={menu.link}>
              <a
                href={menu.link}
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {menu.title}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  const hasValidMealEligibility = swapOutIngredientAssignment.eligibleMealEligibilities?.includes(
    swapInIngredient.mealEligibility
  );

  const renderSwapButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        disableElevation
        size="small"
        onClick={handleConfirmReplacements}
        disabled={
          !canSwapIngredients || !(swapInIngredient.swappability && hasValidMealEligibility)
        }
      >
        Swap
      </Button>
    );
  };

  const renderSwapStatus = (swapStatus) => {
    switch (swapStatus) {
      case 'swapping':
        return <Loading size={20} topPadding={false} centered={false} />;
      case 'swapped':
        return <CheckIcon color="primary" />;
      case 'failed swap':
        // we want to give users the chance to retry swapping if there was an error
        return renderSwapButton();
      default:
        // unswapped, disabled or enabled button based on swappability
        return renderSwapButton();
    }
  };

  const renderErrorMessage = () => {
    if (failedSwap) {
      return (
        <TableRow>
          <TableCell
            colSpan={8}
            className={clsx(classes.errorlessRow, failedSwap && classes.errorRow)}
            align="center"
          >
            <Alert severity="error">
              An error occurred. Please retry the swap again, or make the ingredient change through
              the Admin meal page.
            </Alert>
          </TableCell>
        </TableRow>
      );
    }
    return null;
  };

  const titleCellClassName = clsx(classes.titleCell, failedSwap && classes.erroredCell);
  const cellClassName = clsx(classes.cell, failedSwap && classes.erroredCell);

  return (
    <>
      <TableRow>
        <TableCell className={titleCellClassName}>{swapOutIngredientAssignment.mealId}</TableCell>
        <TableCell className={titleCellClassName}>{swapOutIngredientAssignment.title}</TableCell>
        <TableCell className={cellClassName}>{renderConceptIdLink()}</TableCell>
        <TableCell className={cellClassName}>{renderMenusList()}</TableCell>
        <TableCell className={cellClassName}>{swapOutIngredientAssignment.usageYield}</TableCell>
        <TableCell className={cellClassName}>{swapOutIngredientAssignment.quantity}</TableCell>
        <TableCell className={cellClassName}>{swapOutIngredientAssignment.measure}</TableCell>
        <TableCell className={cellClassName}>{swapOutIngredientAssignment.portionMethod}</TableCell>
        <TableCell className={cellClassName}>
          {swapOutIngredientAssignment.ingredientPackaging}
        </TableCell>
        <TableCell className={cellClassName}>
          {renderSwapStatus(swapOutIngredientAssignment.swapStatus)}
        </TableCell>
      </TableRow>
      {renderErrorMessage()}
      <ConfirmReplacementsDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        ingredientSwapAction={handleSwapWithReplacements}
      />
    </>
  );
};

MealRow.propTypes = {
  swapInIngredient: PropTypes.shape({
    brandName: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    standardUnit: PropTypes.string,
    state: PropTypes.string,
    usageYield: PropTypes.string,
    adminIngredientLink: PropTypes.string,
    swappability: PropTypes.bool,
    mealEligibility: PropTypes.string,
  }),
  swapOutIngredientAssignment: PropTypes.shape({
    id: PropTypes.number,
    mealId: PropTypes.number,
    title: PropTypes.string,
    culinaryVersionId: PropTypes.string,
    adminLink: PropTypes.string,
    menus: PropTypes.arrayOf(PropTypes.object),
    usageYield: PropTypes.number,
    measure: PropTypes.string,
    quantity: PropTypes.number,
    swapStatus: PropTypes.string,
    ingredientPackaging: PropTypes.string,
    portionMethod: PropTypes.string,
    eligibleMealEligibilities: PropTypes.arrayOf(PropTypes.string),
    hasReplacements: PropTypes.bool,
  }).isRequired,
  onPostSwapIngredient: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

MealRow.defaultProps = {
  swapInIngredient: null,
};

export default MealRow;
