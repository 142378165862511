import React from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import styles from '../styles';

const ConfirmReplacementsDirections = () => {
  return (
    <DialogContent>
      <DialogContentText sx={styles.dialogContentText}>
        These swaps include ingredients in meals that have replacements assigned to them. These
        replacements will be invalidated.
      </DialogContentText>
      <DialogContentText sx={styles.dialogContentBoldText}>
        Would you like to continue?
      </DialogContentText>
      <DialogContentText sx={styles.dialogContentBoldText}>
        A list of all replacements will be emailed to swap.replacements@homechef.com
      </DialogContentText>
    </DialogContent>
  );
};

export default ConfirmReplacementsDirections;
