// Dependencies
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

// Helpers
import { dollarStringToNumber } from 'lib/helpers/money';

// Material
import TextField from '@mui/material/TextField';

// Custom Components
import NumberFormatter from '../NumberFormatter';

// Example: https://material-ui.com/components/text-fields/#FormattedInputs.js

const MoneyInput = ({
  children,
  classes,
  disabled,
  helperText,
  label,
  name,
  onSetFormState,
  onValidate,
  fullWidth,
  fieldId,
}) => {
  // State
  const [error, setError] = useState({
    error: false,
  });

  const [dollarAmount, setDollarAmount] = useState(0);

  // Handlers
  const handleOnChange = (event) => {
    const convertedInput = dollarStringToNumber(event.target.value);
    setDollarAmount(convertedInput);
  };

  // Helpers
  const validateField = () => {
    let isValid = false;

    if (!onValidate(dollarAmount)) {
      setError({
        error: true,
      });
    } else {
      setError({
        error: false,
      });
      isValid = true;
    }

    return isValid;
  };

  const validateAndCallAPI = async () => {
    const isValid = await validateField();

    if (isValid) {
      onSetFormState(dollarAmount);
    }
  };

  // eslint-disable-next-line
  const debouncedValidateAndCallAPI = useCallback(debounce(validateAndCallAPI, 250), [
    dollarAmount,
  ]);

  // Hooks
  useEffect(() => {
    debouncedValidateAndCallAPI();

    // Cancel the debounce on useEffect cleanup.
    return debouncedValidateAndCallAPI.cancel;
  }, [dollarAmount, debouncedValidateAndCallAPI]);

  return (
    <TextField
      className={classes.textInput}
      disabled={disabled}
      focused={dollarAmount >= 0}
      error={error.error}
      helperText={helperText}
      label={label}
      name={name}
      onChange={handleOnChange}
      value={dollarAmount}
      variant="outlined"
      InputProps={{
        inputComponent: NumberFormatter,
      }}
      fullWidth={fullWidth}
      id={fieldId}
    >
      {children}
    </TextField>
  );
};

MoneyInput.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onSetFormState: PropTypes.func,
  onValidate: PropTypes.func,
  fieldId: PropTypes.string,
};

MoneyInput.defaultProps = {
  children: [],
  disabled: false,
  fullWidth: false,
  helperText: '',
  label: '',
  name: '',
  onSetFormState: () => {},
  onValidate: null,
  fieldId: '',
};

export default MoneyInput;
