// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SearchField from './SearchField';
import SearchResults from './SearchResults';
import AssignmentFields from './AssignmentFields';
import Error from './Error';

const SwapInIngredientSelector = ({ swapInIngredient, swapInIngredientError }) => {
  return (
    <>
      <SearchField />
      {swapInIngredient.loaded && <SearchResults swapInIngredient={swapInIngredient} />}
      {swapInIngredient.loaded && <AssignmentFields swapInIngredient={swapInIngredient} />}
      {swapInIngredientError && <Error />}
    </>
  );
};

SwapInIngredientSelector.propTypes = {
  swapInIngredient: PropTypes.shape({
    brandName: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    standardUnit: PropTypes.string,
    state: PropTypes.string,
    usageYield: PropTypes.string,
    adminIngredientLink: PropTypes.string,
    quantity: PropTypes.string,
    measure: PropTypes.string,
    loaded: PropTypes.bool,
  }),
  swapInIngredientError: PropTypes.bool,
};

SwapInIngredientSelector.defaultProps = {
  swapInIngredient: null,
  swapInIngredientError: false,
};

export default SwapInIngredientSelector;
