// Dependencies
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

//  Components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Dropdown from 'components/shared/Dropdown';
import FormControlLabel from '@mui/material/FormControlLabel';

// Helpers
import { validUsageYield, validQuantity, validMeasure } from 'lib/helpers/ingredientSwapper';
import { PORTION_METHOD, INGREDIENT_PACKAGING_ID } from 'lib/constants';

const AssignmentFields = ({
  swapInIngredient,
  onSetUsageYield,
  onSetQuantity,
  onSetMeasure,
  onSetSwappability,
  onSetPortionMethod,
  onSetIngredientPackagingId,
  classes,
}) => {
  // State
  const [usageYield, setUsageYield] = useState({
    input: swapInIngredient.usageYield,
    inputError: false,
    inputHelperText: '',
  });

  const [quantity, setQuantity] = useState({
    input: undefined,
    inputError: false,
    inputHelperText: '',
  });

  const [measure, setMeasure] = useState({
    input: '',
    inputError: false,
    inputHelperText: '',
  });

  const [isPortionMethodEnabled, setIsPortionMethodEnabled] = useState(false);
  const [portionMethod, setPortionMethod] = useState(null);

  const [isIngredientPackagingEnabled, setIsIngredientPackagingDisabled] = useState(false);
  const [ingredientPackagingId, setIngredientPackagingId] = useState(null);

  const SWAP_INCOMPATIBLE_STATES = ['Legacy', 'Wip'];

  // Helpers
  const isVolumeUnit = swapInIngredient.standardUnit === 'volume';

  const handlePortionMethodCheck = () => {
    const nextIsPortionMethodEnabled = !isPortionMethodEnabled;
    setIsPortionMethodEnabled(nextIsPortionMethodEnabled);

    if (nextIsPortionMethodEnabled) {
      setPortionMethod(swapInIngredient.portionMethod);
    } else {
      setPortionMethod(null);
    }
  };
  const selectPortionMethods = useMemo(() => {
    return swapInIngredient.dropdownOptions.portionMethods.map((pm) => ({
      label: pm.formattedName,
      value: pm.name,
    }));
  }, [swapInIngredient.dropdownOptions.portionMethods]);

  const handleIngredientPackagingCheck = () => {
    const nextisIngredientPackagingEnabled = !isIngredientPackagingEnabled;
    setIsIngredientPackagingDisabled(nextisIngredientPackagingEnabled);

    if (nextisIngredientPackagingEnabled) {
      setIngredientPackagingId(swapInIngredient.ingredientPackagingId);
    } else {
      setIngredientPackagingId(null);
    }
  };
  const selectIngredientPackagings = useMemo(() => {
    return swapInIngredient.dropdownOptions.ingredientPackagings.map((ingredientPackaging) => ({
      label: ingredientPackaging.formattedName,
      value: ingredientPackaging.id,
    }));
  }, [swapInIngredient.dropdownOptions.ingredientPackagings]);

  // Handler Methods
  const handleSetUsageYieldInput = (ref) => {
    const { value } = ref.target;
    const trimmedValue = value.trim();
    setUsageYield({ input: trimmedValue, inputError: false, inputHelperText: '' });

    if (swapInIngredient.swappability === true) {
      onSetSwappability(false);
    }
  };

  const handleSetQuantityInput = (ref) => {
    const { value } = ref.target;
    const trimmedValue = value.trim();
    setQuantity({ input: trimmedValue, inputError: false, inputHelperText: '' });

    if (swapInIngredient.swappability === true) {
      onSetSwappability(false);
    }
  };

  const handleSetMeasureInput = (ref) => {
    const { value } = ref.target;
    setMeasure({ input: value, inputError: false, inputHelperText: '' });

    if (swapInIngredient.swappability === true) {
      onSetSwappability(false);
    }
  };

  const handleSetPortionMethod = (ref) => {
    const { value } = ref.target;
    setPortionMethod(value);

    if (swapInIngredient.swappability === true) {
      onSetSwappability(false);
    }
  };

  const handleSetIngredientPackagingId = (ref) => {
    const { value } = ref.target;
    setIngredientPackagingId(value);

    if (swapInIngredient.swappability === true) {
      onSetSwappability(false);
    }
  };

  const handleApplyAttributes = () => {
    const usageYieldValid = validUsageYield(usageYield.input);
    const quantityValid = validQuantity(quantity.input);
    const measureValid = validMeasure(measure.input, isVolumeUnit);

    if (usageYieldValid && quantityValid && measureValid) {
      onSetUsageYield(usageYield.input);
      onSetQuantity(quantity.input);
      onSetMeasure(measure.input);
      onSetPortionMethod(portionMethod);
      onSetIngredientPackagingId(ingredientPackagingId);
      onSetSwappability(true);
      return;
    }

    if (!quantityValid) {
      setQuantity({ ...quantity, inputError: true, inputHelperText: 'Invalid quantity.' });
    }

    if (!usageYieldValid) {
      setUsageYield({ ...usageYield, inputError: true, inputHelperText: 'Invalid usage yield.' });
    }

    if (!measureValid) {
      setMeasure({ ...measure, inputError: true, inputHelperText: 'Measure required.' });
    }
  };

  // Rendering
  return (
    <Grid container direction="column" spacing={1} className={classes.grid}>
      <Grid item xs={12}>
        <TextField
          id="swappable-ingredient-display-name"
          variant="outlined"
          label="Display Name"
          size="small"
          color="secondary"
          value={swapInIngredient.displayName || ''}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="swappable-ingredient-usage-yield"
          variant="outlined"
          label="Usage Yield"
          size="small"
          color="secondary"
          error={usageYield.inputError}
          helperText={usageYield.inputHelperText}
          onChange={handleSetUsageYieldInput}
          defaultValue={swapInIngredient.usageYield || ''}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="swappable-ingredient-quantity"
          variant="outlined"
          label="Quantity"
          size="small"
          color="secondary"
          error={quantity.inputError}
          helperText={quantity.inputHelperText}
          onChange={handleSetQuantityInput}
        />
      </Grid>

      {isVolumeUnit ? (
        <Grid item xs={6}>
          <TextField
            select
            id="swappable-ingredient-measure"
            variant="outlined"
            label="Measure"
            size="small"
            fullWidth
            color="secondary"
            error={measure.inputError}
            helperText={measure.inputHelperText}
            onChange={handleSetMeasureInput}
            value={measure.input}
          >
            <MenuItem value="">Measure</MenuItem>
            <MenuItem value="cup">cup</MenuItem>
            <MenuItem value="tbsp">Tbsp</MenuItem>
            <MenuItem value="tsp">tsp</MenuItem>
          </TextField>
        </Grid>
      ) : null}

      <Grid item xs={6}>
        <FormControlLabel
          control={<Checkbox onChange={handlePortionMethodCheck} />}
          label="Apply Portion Method"
        />
        {isPortionMethodEnabled && (
          <Dropdown
            label="Portion Method"
            name={PORTION_METHOD}
            onChange={handleSetPortionMethod}
            value={portionMethod}
            options={selectPortionMethods}
          />
        )}
      </Grid>

      <Grid item xs={6}>
        <FormControlLabel
          control={<Checkbox onChange={handleIngredientPackagingCheck} />}
          label="Apply Ingredient Packaging"
        />
        {isIngredientPackagingEnabled && (
          <Dropdown
            label="Ingredient Packaging"
            name={INGREDIENT_PACKAGING_ID}
            onChange={handleSetIngredientPackagingId}
            value={ingredientPackagingId}
            options={selectIngredientPackagings}
          />
        )}
      </Grid>

      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleApplyAttributes}
          disabled={SWAP_INCOMPATIBLE_STATES.includes(swapInIngredient.state)}
        >
          Enable Swap
        </Button>
      </Grid>
    </Grid>
  );
};

AssignmentFields.propTypes = {
  swapInIngredient: PropTypes.shape({
    brandName: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    standardUnit: PropTypes.string,
    state: PropTypes.string,
    usageYield: PropTypes.string,
    adminIngredientLink: PropTypes.string,
    quantity: PropTypes.string,
    measure: PropTypes.string,
    portionMethod: PropTypes.string,
    ingredientPackagingId: PropTypes.number,
    swappability: PropTypes.bool,
    dropdownOptions: PropTypes.shape({
      portionMethods: PropTypes.array,
      ingredientPackagings: PropTypes.array,
    }),
  }),
  classes: PropTypes.object.isRequired,
  onSetUsageYield: PropTypes.func.isRequired,
  onSetQuantity: PropTypes.func.isRequired,
  onSetMeasure: PropTypes.func.isRequired,
  onSetPortionMethod: PropTypes.func.isRequired,
  onSetIngredientPackagingId: PropTypes.func.isRequired,
  onSetSwappability: PropTypes.func.isRequired,
};

AssignmentFields.defaultProps = {
  swapInIngredient: null,
};

export default AssignmentFields;
