export const apiRequestConstant = (type) => {
  return `${type}_REQUEST`;
};
export const apiSuccessConstant = (type) => {
  return `${type}_SUCCESS`;
};
export const apiErrorConstant = (type) => {
  return `${type}_ERROR`;
};

// Session
export const SESSION = 'SESSION';
export const SESSION_REQUEST = apiRequestConstant(SESSION);
export const SESSION_SUCCESS = apiSuccessConstant(SESSION);
export const SESSION_ERROR = apiErrorConstant(SESSION);

// APP CONFIGURATION TOOL
export const GET_ALL_CONFIGURATIONS = 'GET_ALL_CONFIGURATIONS';
export const GET_ALL_CONFIGURATIONS_REQUEST = apiRequestConstant(GET_ALL_CONFIGURATIONS);
export const GET_ALL_CONFIGURATIONS_SUCCESS = apiSuccessConstant(GET_ALL_CONFIGURATIONS);
export const GET_ALL_CONFIGURATIONS_ERROR = apiErrorConstant(GET_ALL_CONFIGURATIONS);

export const CREATE_CONFIGURATION_GROUP = 'CREATE_CONFIGURATION_GROUP';
export const CREATE_CONFIGURATION_GROUP_REQUEST = apiRequestConstant(CREATE_CONFIGURATION_GROUP);
export const CREATE_CONFIGURATION_GROUP_SUCCESS = apiSuccessConstant(CREATE_CONFIGURATION_GROUP);
export const CREATE_CONFIGURATION_GROUP_ERROR = apiErrorConstant(CREATE_CONFIGURATION_GROUP);

export const UPDATE_CONFIGURATION_GROUP = 'UPDATE_CONFIGURATION_GROUP';
export const UPDATE_CONFIGURATION_GROUP_REQUEST = apiRequestConstant(UPDATE_CONFIGURATION_GROUP);
export const UPDATE_CONFIGURATION_GROUP_SUCCESS = apiSuccessConstant(UPDATE_CONFIGURATION_GROUP);
export const UPDATE_CONFIGURATION_GROUP_ERROR = apiErrorConstant(UPDATE_CONFIGURATION_GROUP);

export const REMOVE_CONFIGURATION_GROUP = 'REMOVE_CONFIGURATION_GROUP';
export const REMOVE_CONFIGURATION_GROUP_REQUEST = apiRequestConstant(REMOVE_CONFIGURATION_GROUP);
export const REMOVE_CONFIGURATION_GROUP_SUCCESS = apiSuccessConstant(REMOVE_CONFIGURATION_GROUP);
export const REMOVE_CONFIGURATION_GROUP_ERROR = apiErrorConstant(REMOVE_CONFIGURATION_GROUP);

export const CREATE_CONFIGURATION_KEY = 'CREATE_CONFIGURATION_KEY';
export const CREATE_CONFIGURATION_KEY_REQUEST = apiRequestConstant(CREATE_CONFIGURATION_KEY);
export const CREATE_CONFIGURATION_KEY_SUCCESS = apiSuccessConstant(CREATE_CONFIGURATION_KEY);
export const CREATE_CONFIGURATION_KEY_ERROR = apiErrorConstant(CREATE_CONFIGURATION_KEY);

export const UPDATE_CONFIGURATION_KEY = 'UPDATE_CONFIGURATION_KEY';
export const UPDATE_CONFIGURATION_KEY_REQUEST = apiRequestConstant(UPDATE_CONFIGURATION_KEY);
export const UPDATE_CONFIGURATION_KEY_SUCCESS = apiSuccessConstant(UPDATE_CONFIGURATION_KEY);
export const UPDATE_CONFIGURATION_KEY_ERROR = apiErrorConstant(UPDATE_CONFIGURATION_KEY);

export const DELETE_CONFIGURATION_KEY = 'DELETE_CONFIGURATION_KEY';
export const DELETE_CONFIGURATION_KEY_REQUEST = apiRequestConstant(DELETE_CONFIGURATION_KEY);
export const DELETE_CONFIGURATION_KEY_SUCCESS = apiSuccessConstant(DELETE_CONFIGURATION_KEY);
export const DELETE_CONFIGURATION_KEY_ERROR = apiErrorConstant(DELETE_CONFIGURATION_KEY);

export const RESTART_NOW = 'RESTART_NOW';
export const RESTART_NOW_REQUEST = apiRequestConstant(RESTART_NOW);
export const RESTART_NOW_SUCCESS = apiSuccessConstant(RESTART_NOW);
export const RESTART_NOW_ERROR = apiErrorConstant(RESTART_NOW);

export const SET_FOCUS_KEY_ID = 'SET_FOCUS_KEY_ID';
export const CLEAR_FOCUS_KEY_ID = 'CLEAR_FOCUS_KEY_ID';

export const UPDATE_TAB_INDEX = 'UPDATE_TAB_INDEX';
export const CLOSE_RESTART_DIALOG = 'CLOSE_RESTART_DIALOG';
export const CLOSE_DEPLOY_LOCK_DIALOG = 'CLOSE_DEPLOY_LOCK_DIALOG';

export const SET_FORM_DATA = 'SET_FORM_DATA';

// COMPARISON TOOL
export const CT_MEALS = 'CT_MEALS';
export const CT_MEALS_REQUEST = apiRequestConstant(CT_MEALS);
export const CT_MEALS_SUCCESS = apiSuccessConstant(CT_MEALS);
export const CT_MEALS_ERROR = apiErrorConstant(CT_MEALS);

export const CT_SET_CONCEPT = 'CT_SET_CONCEPT';
export const CT_RESET_ERROR_MESSAGE = 'CT_RESET_ERROR_MESSAGE';

export const CT_SET_CHECKED_MEALS = 'CT_SET_CHECKED_MEALS';
export const CT_FILTER_VIEWABLE_MEALS = 'CT_FILTER_VIEWABLE_MEALS';
export const CT_SET_OPEN_SELECT_VERSIONS = 'CT_SET_OPEN_SELECT_VERSIONS';

export const CT_UPDATE_MEAL_VERSION_DATA = 'CT_UPDATE_MEAL_VERSION_DATA';
export const CT_UPDATE_MEAL_VERSION_DATA_REQUEST = apiRequestConstant(CT_UPDATE_MEAL_VERSION_DATA);
export const CT_UPDATE_MEAL_VERSION_DATA_SUCCESS = apiSuccessConstant(CT_UPDATE_MEAL_VERSION_DATA);
export const CT_UPDATE_MEAL_VERSION_DATA_ERROR = apiErrorConstant(CT_UPDATE_MEAL_VERSION_DATA);

// Flexible Volume Changes
export const FVC_SET_CHANGE_TYPE = 'FVC_SET_CHANGE_TYPE';
export const FVC_SET_CHANGE_REQUEST_DATA = 'FVC_SET_CHANGE_REQUEST_DATA';
export const FVC_ADD_ANOTHER_CHANGE_REQUEST = 'FVC_ADD_ANOTHER_CHANGE_REQUEST';
export const FVC_POST_SUBMIT_CHANGE_REQUESTS = 'FVC_POST_SUBMIT_CHANGE_REQUESTS';
export const FVC_POST_SUBMIT_CHANGE_REQUESTS_REQUEST = apiRequestConstant(
  FVC_POST_SUBMIT_CHANGE_REQUESTS
);
export const FVC_POST_SUBMIT_CHANGE_REQUESTS_SUCCESS = apiSuccessConstant(
  FVC_POST_SUBMIT_CHANGE_REQUESTS
);
export const FVC_POST_SUBMIT_CHANGE_REQUESTS_ERROR = apiErrorConstant(
  FVC_POST_SUBMIT_CHANGE_REQUESTS
);

export const FVC_SET_IS_DRAWER_OPEN = 'FVC_SET_IS_DRAWER_OPEN';
export const FVC_SET_IS_CONFIRMATION_DIALOG_OPEN = 'FVC_SET_IS_CONFIRMATION_DIALOG_OPEN';
export const FVC_DISMISS_ALERT = 'FVC_DISMISS_ALERT';
export const FVC_DISMISS_CHANGE_REQUEST_FORM_ERROR = 'FVC_DISMISS_CHANGE_REQUEST_FORM_ERROR';

// MENU MANAGER Actions
// Menu
export const MM_CREATE_NEW_MENU = 'MM_CREATE_NEW_MENU';
export const MM_CREATE_NEW_MENU_REQUEST = apiRequestConstant(MM_CREATE_NEW_MENU);
export const MM_CREATE_NEW_MENU_SUCCESS = apiSuccessConstant(MM_CREATE_NEW_MENU);
export const MM_CREATE_NEW_MENU_ERROR = apiErrorConstant(MM_CREATE_NEW_MENU);

export const MM_FETCH_MENU = 'MM_FETCH_MENU';
export const MM_FETCH_MENU_REQUEST = apiRequestConstant(MM_FETCH_MENU);
export const MM_FETCH_MENU_SUCCESS = apiSuccessConstant(MM_FETCH_MENU);
export const MM_FETCH_MENU_ERROR = apiErrorConstant(MM_FETCH_MENU);

export const MM_TOGGLE_ADD_MEALS_DIALOG = 'MM_TOGGLE_ADD_MEALS_DIALOG';
export const MM_TOGGLE_SNACKBAR = 'MM_TOGGLE_SNACKBAR';

export const MM_SET_RANKING = 'MM_SET_RANKING';

export const MM_ADD_MEALS = 'MM_ADD_MEALS';
export const MM_ADD_MEALS_REQUEST = apiRequestConstant(MM_ADD_MEALS);
export const MM_ADD_MEALS_SUCCESS = apiSuccessConstant(MM_ADD_MEALS);
export const MM_ADD_MEALS_ERROR = apiErrorConstant(MM_ADD_MEALS);

export const MM_UPDATE_RANKING = 'MM_UPDATE_RANKING';
export const MM_POST_RANKING = 'MM_POST_RANKING';
export const MM_POST_RANKING_REQUEST = apiRequestConstant(MM_POST_RANKING);
export const MM_POST_RANKING_SUCCESS = apiSuccessConstant(MM_POST_RANKING);
export const MM_POST_RANKING_ERROR = apiErrorConstant(MM_POST_RANKING);

export const MM_REMOVE_MEAL = 'MM_REMOVE_MEAL';
export const MM_REMOVE_MEAL_REQUEST = apiRequestConstant(MM_REMOVE_MEAL);
export const MM_REMOVE_MEAL_SUCCESS = apiSuccessConstant(MM_REMOVE_MEAL);
export const MM_REMOVE_MEAL_ERROR = apiErrorConstant(MM_REMOVE_MEAL);

// Menu Selector
export const MM_SET_SELECTED_MENU_ID = 'MM_SET_SELECTED_MENU_ID';
export const MM_SET_SELECTED_MEAL_ID = 'MM_SET_SELECTED_MEAL_ID';
export const MM_FETCH_ALL_MENUS = 'MM_FETCH_ALL_MENUS';
export const MM_FETCH_ALL_MENUS_REQUEST = apiRequestConstant(MM_FETCH_ALL_MENUS);
export const MM_FETCH_ALL_MENUS_SUCCESS = apiSuccessConstant(MM_FETCH_ALL_MENUS);
export const MM_FETCH_ALL_MENUS_ERROR = apiErrorConstant(MM_FETCH_ALL_MENUS);

// INCIDENT MANAGER
export const IM_TOGGLE_SNACKBAR = 'IM_TOGGLE_SNACKBAR';
export const IM_INCIDENTABLE = 'IM_INCIDENTABLE';
export const IM_TAX_REFUND = 'IM_TAX_REFUND';
export const IM_GIFT_CARD_TAX_REFUND = 'IM_GIFT_CARD_TAX_REFUND';
export const IM_CANCEL = 'IM_CANCEL';
export const IM_INCIDENTABLE_REQUEST = apiRequestConstant(IM_INCIDENTABLE);
export const IM_INCIDENTABLE_SUCCESS = apiSuccessConstant(IM_INCIDENTABLE);
export const IM_INCIDENTABLE_ERROR = apiErrorConstant(IM_INCIDENTABLE);
export const IM_TAX_REFUND_REQUEST = apiRequestConstant(IM_INCIDENTABLE);
export const IM_TAX_REFUND_SUCCESS = apiSuccessConstant(IM_INCIDENTABLE);
export const IM_TAX_REFUND_ERROR = apiErrorConstant(IM_INCIDENTABLE);
export const IM_FORM_OPTIONS = 'IM_FORM_OPTIONS';
export const IM_FORM_OPTIONS_REQUEST = apiRequestConstant(IM_FORM_OPTIONS);
export const IM_FORM_OPTIONS_SUCCESS = apiSuccessConstant(IM_FORM_OPTIONS);
export const IM_FORM_OPTIONS_ERROR = apiErrorConstant(IM_FORM_OPTIONS);
export const IM_INCIDENT = 'IM_INCIDENT';
export const IM_INCIDENT_REQUEST = apiRequestConstant(IM_INCIDENT);
export const IM_INCIDENT_SUCCESS = apiSuccessConstant(IM_INCIDENT);
export const IM_INCIDENT_ERROR = apiErrorConstant(IM_INCIDENT);
export const IM_CANCEL_REQUEST = apiRequestConstant(IM_CANCEL);
export const IM_CANCEL_SUCCESS = apiSuccessConstant(IM_CANCEL);
export const IM_CANCEL_ERROR = apiErrorConstant(IM_CANCEL);

// INGREDIENT SWAPPER

export const ISW_SWAP_OUT_INGREDIENT = 'ISW_SWAP_OUT_INGREDIENT';
export const ISW_SWAP_OUT_INGREDIENT_REQUEST = apiRequestConstant(ISW_SWAP_OUT_INGREDIENT);
export const ISW_SWAP_OUT_INGREDIENT_SUCCESS = apiSuccessConstant(ISW_SWAP_OUT_INGREDIENT);
export const ISW_SWAP_OUT_INGREDIENT_ERROR = apiErrorConstant(ISW_SWAP_OUT_INGREDIENT);

export const ISW_SWAP_OUT_INGREDIENT_MEALS = 'ISW_SWAP_OUT_INGREDIENT_MEALS';
export const ISW_SWAP_OUT_INGREDIENT_MEALS_REQUEST = apiRequestConstant(
  ISW_SWAP_OUT_INGREDIENT_MEALS
);
export const ISW_SWAP_OUT_INGREDIENT_MEALS_SUCCESS = apiSuccessConstant(
  ISW_SWAP_OUT_INGREDIENT_MEALS
);
export const ISW_SWAP_OUT_INGREDIENT_MEALS_ERROR = apiErrorConstant(ISW_SWAP_OUT_INGREDIENT_MEALS);

export const ISW_SWAP_IN_INGREDIENT = 'ISW_SWAP_IN_INGREDIENT';
export const ISW_SWAP_IN_INGREDIENT_REQUEST = apiRequestConstant(ISW_SWAP_IN_INGREDIENT);
export const ISW_SWAP_IN_INGREDIENT_SUCCESS = apiSuccessConstant(ISW_SWAP_IN_INGREDIENT);
export const ISW_SWAP_IN_INGREDIENT_ERROR = apiErrorConstant(ISW_SWAP_IN_INGREDIENT);

export const ISW_USAGE_YIELD = 'ISW_USAGE_YIELD';
export const ISW_QUANTITY = 'ISW_QUANTITY';
export const ISW_MEASURE = 'ISW_MEASURE';
export const ISW_PORTION_METHOD = 'ISW_PORTION_METHOD';
export const ISW_INGREDIENT_PACKAGING_ID = 'ISW_INGREDIENT_PACKAGING_ID';

export const ISW_SET_SWAPPABILITY = 'ISW_SET_SWAPPABILITY';

export const ISW_SWAP_INGREDIENT = 'ISW_SWAP_INGREDIENT';
export const ISW_SWAP_INGREDIENT_REQUEST = apiRequestConstant(ISW_SWAP_INGREDIENT);
export const ISW_SWAP_INGREDIENT_SUCCESS = apiSuccessConstant(ISW_SWAP_INGREDIENT);
export const ISW_SWAP_INGREDIENT_ERROR = apiErrorConstant(ISW_SWAP_INGREDIENT);

export const ISW_RESET_ERROR_MESSAGE = 'ISW_RESET_ERROR_MESSAGE';

// DISPUTED CHARGES

export const DC_DISPUTES = 'DC_DISPUTES';
export const DC_DISPUTES_REQUEST = apiRequestConstant(DC_DISPUTES);
export const DC_DISPUTES_SUCCESS = apiSuccessConstant(DC_DISPUTES);
export const DC_DISPUTES_ERROR = apiErrorConstant(DC_DISPUTES);

export const DC_EVIDENCE = 'DC_EVIDENCE';
export const DC_EVIDENCE_REQUEST = apiRequestConstant(DC_EVIDENCE);
export const DC_EVIDENCE_SUCCESS = apiSuccessConstant(DC_EVIDENCE);
export const DC_EVIDENCE_ERROR = apiErrorConstant(DC_EVIDENCE);

// MEAL EDIT FORM

export const MEF_MEAL = 'MEF_MEAL';
export const MEF_MEAL_REQUEST = apiRequestConstant(MEF_MEAL);
export const MEF_MEAL_SUCCESS = apiSuccessConstant(MEF_MEAL);
export const MEF_MEAL_ERROR = apiErrorConstant(MEF_MEAL);

export const MEF_UPDATE_MEAL = 'MEF_UPDATE_MEAL';
export const MEF_UPDATE_MEAL_REQUEST = apiRequestConstant(MEF_UPDATE_MEAL);
export const MEF_UPDATE_MEAL_SUCCESS = apiSuccessConstant(MEF_UPDATE_MEAL);
export const MEF_UPDATE_MEAL_ERROR = apiErrorConstant(MEF_UPDATE_MEAL);

export const MEF_FORM_OPTIONS = 'MEF_FORM_OPTIONS';
export const MEF_FORM_OPTIONS_REQUEST = apiRequestConstant(MEF_FORM_OPTIONS);
export const MEF_FORM_OPTIONS_SUCCESS = apiSuccessConstant(MEF_FORM_OPTIONS);
export const MEF_FORM_OPTIONS_ERROR = apiErrorConstant(MEF_FORM_OPTIONS);

export const MEF_CLEAR_MESSAGES = 'MEF_CLEAR_MESSAGES';

// MENUGRID

export const GET_QUARTER = 'GET_QUARTER';
export const GET_QUARTER_REQUEST = apiRequestConstant(GET_QUARTER);
export const GET_QUARTER_SUCCESS = apiSuccessConstant(GET_QUARTER);
export const GET_QUARTER_ERROR = apiErrorConstant(GET_QUARTER);

export const CHANGE_QUARTER = 'CHANGE_QUARTER';
export const CHANGE_QUARTER_REQUEST = apiRequestConstant(CHANGE_QUARTER);
export const CHANGE_QUARTER_SUCCESS = apiSuccessConstant(CHANGE_QUARTER);
export const CHANGE_QUARTER_ERROR = apiErrorConstant(CHANGE_QUARTER);

export const GRID_DATA = 'GRID_DATA';
export const GRID_DATA_REQUEST = apiRequestConstant(GRID_DATA);
export const GRID_DATA_SUCCESS = apiSuccessConstant(GRID_DATA);
export const GRID_DATA_ERROR = apiErrorConstant(GRID_DATA);

export const CSV_EXPORT = 'CSV_EXPORT';
export const CSV_EXPORT_REQUEST = apiRequestConstant(CSV_EXPORT);
export const CSV_EXPORT_SUCCESS = apiSuccessConstant(CSV_EXPORT);
export const CSV_EXPORT_ERROR = apiErrorConstant(CSV_EXPORT);

export const UPDATE_SELECTION_ORDER = 'UPDATE_SELECTION_ORDER';
export const UPDATE_SELECTION_ORDER_ON_GRID = 'UPDATE_SELECTION_ORDER_ON_GRID';
export const UPDATE_SELECTION_ORDER_SUCCESS = apiSuccessConstant(UPDATE_SELECTION_ORDER);
export const UPDATE_SELECTION_ORDER_ERROR = apiErrorConstant(UPDATE_SELECTION_ORDER);

export const ADD_STAGING_SELECTIONS = 'ADD_STAGING_SELECTIONS';
export const ADD_STAGING_SELECTIONS_SUCCESS = apiSuccessConstant(ADD_STAGING_SELECTIONS);
export const ADD_STAGING_SELECTIONS_ERROR = apiErrorConstant(ADD_STAGING_SELECTIONS);

export const TOGGLE_ADD_MEALS_DIALOG = 'TOGGLE_ADD_MEALS_DIALOG';
export const TOGGLE_SNACK_BAR = 'TOGGLE_SNACK_BAR';

export const ADD_PLACEHOLDER = 'ADD_PLACEHOLDER';
export const REMOVE_PLACEHOLDER = 'REMOVE_PLACEHOLDER';
export const REMOVE_MEAL = 'REMOVE_MEAL';

export const SINGLE_MENU_DATA = 'SINGLE_MENU_DATA';
export const SINGLE_MENU_DATA_REQUEST = apiRequestConstant(SINGLE_MENU_DATA);
export const SINGLE_MENU_DATA_SUCCESS = apiSuccessConstant(SINGLE_MENU_DATA);
export const SINGLE_MENU_DATA_ERROR = apiErrorConstant(SINGLE_MENU_DATA);

// COMPOUND INGREDIENT RECIPE FORM

export const CIRF_COMPOUND_INGREDIENT_RECIPE = 'CIRF_COMPOUND_INGREDIENT_RECIPE';
export const CIRF_COMPOUND_INGREDIENT_RECIPE_REQUEST = apiRequestConstant(
  CIRF_COMPOUND_INGREDIENT_RECIPE
);
export const CIRF_COMPOUND_INGREDIENT_RECIPE_SUCCESS = apiSuccessConstant(
  CIRF_COMPOUND_INGREDIENT_RECIPE
);
export const CIRF_COMPOUND_INGREDIENT_RECIPE_ERROR = apiErrorConstant(
  CIRF_COMPOUND_INGREDIENT_RECIPE
);

export const CIRF_UPDATE_CIR = 'CIRF_UPDATE_CIR';
export const CIRF_UPDATE_CIR_REQUEST = apiRequestConstant(CIRF_UPDATE_CIR);
export const CIRF_UPDATE_CIR_SUCCESS = apiSuccessConstant(CIRF_UPDATE_CIR);
export const CIRF_UPDATE_CIR_ERROR = apiErrorConstant(CIRF_UPDATE_CIR);

export const CIRF_UPDATE_CI_STATE = 'CIRF_UPDATE_CI_STATE';
export const CIRF_UPDATE_CI_STATE_REQUEST = apiRequestConstant(CIRF_UPDATE_CI_STATE);
export const CIRF_UPDATE_CI_STATE_SUCCESS = apiSuccessConstant(CIRF_UPDATE_CI_STATE);
export const CIRF_UPDATE_CI_STATE_ERROR = apiErrorConstant(CIRF_UPDATE_CI_STATE);

export const CIRF_FORM_OPTIONS = 'CIRF_FORM_OPTIONS';
export const CIRF_FORM_OPTIONS_REQUEST = apiRequestConstant(CIRF_FORM_OPTIONS);
export const CIRF_FORM_OPTIONS_SUCCESS = apiSuccessConstant(CIRF_FORM_OPTIONS);
export const CIRF_FORM_OPTIONS_ERROR = apiErrorConstant(CIRF_FORM_OPTIONS);

export const CIRF_CLEAR_MESSAGES = 'CIRF_CLEAR_MESSAGES';
