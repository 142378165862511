// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Material
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

// Components
import MealRow from './MealRow';

const SwapOutIngredientMeals = ({
  swapInIngredient,
  swapOutIngredient,
  onGetSwapOutIngredientMeals,
  onPostSwapIngredient,
  formState,
  handleFormState,
  classes,
}) => {
  const handleLiveMealsChange = () => {
    const showLiveMeals = !formState.showLiveMeals;

    handleFormState({ target: { name: 'showLiveMeals', value: showLiveMeals } });
    onGetSwapOutIngredientMeals(swapOutIngredient.id, showLiveMeals, formState.showUnassignedMeals);
  };

  const handleUnassignedMealsChange = () => {
    const showUnassignedMeals = !formState.showUnassignedMeals;

    handleFormState({ target: { name: 'showUnassignedMeals', value: showUnassignedMeals } });
    onGetSwapOutIngredientMeals(swapOutIngredient.id, formState.showLiveMeals, showUnassignedMeals);
  };

  // Rendering
  return (
    <Container>
      <FormControlLabel
        control={<Switch onChange={handleLiveMealsChange} name="showLiveMeals" />}
        label="Include LIVE meals"
        className={classes.switchLabel}
      />
      <FormControlLabel
        control={<Switch onChange={handleUnassignedMealsChange} name="showUnassignedMeals" />}
        label="Include meals not assigned to menus"
        className={classes.switchLabel}
      />
      <h3 className={classes.title}>2. Swap Ingredients</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.titleCell}>Meal ID</TableCell>
            <TableCell className={classes.titleCell}>Meal Name</TableCell>
            <TableCell className={classes.cell}>Culinary ID</TableCell>
            <TableCell className={classes.cell}>Menu</TableCell>
            <TableCell className={classes.cell}>Usage Yield</TableCell>
            <TableCell className={classes.cell}>Qty</TableCell>
            <TableCell className={classes.cell}>Measure</TableCell>
            <TableCell className={classes.cell}>Portion Method</TableCell>
            <TableCell className={classes.cell}>Ingredient Packaging</TableCell>
            <TableCell className={classes.cell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {swapOutIngredient.ingredientAssignments.map((ingredientAssignment) => (
            <MealRow
              swapInIngredient={swapInIngredient}
              swapOutIngredientAssignment={ingredientAssignment}
              onPostSwapIngredient={onPostSwapIngredient}
              key={ingredientAssignment.id}
            />
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

SwapOutIngredientMeals.propTypes = {
  swapInIngredient: PropTypes.shape({
    brandName: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    standardUnit: PropTypes.string,
    state: PropTypes.string,
    usageYield: PropTypes.string,
    adminIngredientLink: PropTypes.string,
    ingredientAssignments: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  swapOutIngredient: PropTypes.shape({
    brandName: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    standardUnit: PropTypes.string,
    state: PropTypes.string,
    usageYield: PropTypes.string,
    adminIngredientLink: PropTypes.string,
    ingredientAssignments: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onPostSwapIngredient: PropTypes.func.isRequired,
  onGetSwapOutIngredientMeals: PropTypes.func.isRequired,
  handleFormState: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default SwapOutIngredientMeals;
