import React from 'react';
import {
  ArrayField,
  DatagridConfigurable,
  ExportButton,
  List,
  SearchInput,
  SelectColumnsButton,
  SelectInput,
  SimpleList,
  TextField,
  TopToolbar,
  WithRecord,
  useListContext,
} from 'react-admin';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { APP_API_URI } from 'lib/constants';
import ClearFiltersInput from '../../sharedComponents/ClearFiltersInput';
import CustomFilterInput from '../../sharedComponents/CustomFilterInput';
import { useFilters, useAggregatedData } from './CompoundIngredientsQueries';

const ListActions = () => {
  return (
    <TopToolbar>
      <SelectColumnsButton />
      <ExportButton />
    </TopToolbar>
  );
};

// These custom inputs are necessary because react-admin only allows input components to be included in filters.
// This solution wraps the not-a-filter input (chip, button, etc.) inside a component that can be accepted as an input.
const compoundIngredientFilters = (stateFilters, startDate, endDate) => [
  <CustomFilterInput key="startDate" source={startDate} labelText={startDate} alwaysOn readOnly />,
  <p key="arrow">-</p>,
  <CustomFilterInput
    key="endDate"
    source={endDate}
    labelText={endDate}
    alwaysOn={!!endDate}
    readOnly
  />,
  <SearchInput key="search" source="q" placeholder="Search" alwaysOn />,
  <SelectInput key="state" source="state" alwaysOn choices={stateFilters} />,
  <ClearFiltersInput key="clear" labelText="Clear Filters" alwaysOn />,
];

const CompoundIngredientsList = () => {
  const { data: filterData } = useFilters();
  const { data: { aggregatedDataByMenu } = {} } = useAggregatedData();
  const { filterValues } = useListContext();

  // Checks the filters to see if we are filtering by a specific menu ID and then if we are,
  // it finds the correlated start date in order to display that only. In that case we will not display an end date.
  // In the case that we are not filtering by a specific menu, then we'll display the start ship date and end ship date coming in from the filter data request
  const menuId = filterValues?.menu_id;
  const clickedMenu = aggregatedDataByMenu?.find((obj) => obj.menuId === menuId);
  const startDate = clickedMenu ? clickedMenu.startDate : filterData?.startShipDate || '';
  const endDate = clickedMenu ? '' : filterData?.endShipDate || '';

  const stateFilters = filterData?.states.map((state) => ({ id: state, name: state })) || [];

  return (
    <List
      actions={<ListActions />}
      filters={compoundIngredientFilters(stateFilters, startDate, endDate)}
    >
      <DatagridConfigurable>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="displayName" />
        <TextField source="standardUnit" />
        <TextField source="state" />
        <ArrayField source="yieldsByFacility" reference="yieldsByFacility" sortable={false}>
          <SimpleList
            primaryText={(record) => record.packingFacility}
            secondaryText={(record) => record.yieldNumber}
            key={(record) => record.id}
          />
        </ArrayField>
        <TextField source="ingredientClassificationName" sortable={false} />
        <ArrayField source="allergens" reference="allergens" label="Allergens" sortable={false}>
          <SimpleList empty={<p>No allergens to display</p>} primaryText={(record) => record} />
        </ArrayField>
        <ArrayField source="overOrdersByFacility" reference="overOrdersByFacility" sortable={false}>
          <SimpleList
            primaryText={(record) => record.packingFacility}
            secondaryText={(record) => record.overOrder}
            key={(record) => record.id}
          />
        </ArrayField>
        <TextField source="outputYield" sortable={false} />
        <TextField source="outputPerRunMetric" sortable={false} />
        <TextField source="minOutputPerRun" sortable={false} />
        <TextField source="maxOutputPerRun" sortable={false} />
        <WithRecord
          render={(record) => (
            <Button
              color="primary"
              component={Link}
              to={`${APP_API_URI}/admin/compound_ingredients/${record.id}`}
            >
              View
            </Button>
          )}
        />
      </DatagridConfigurable>
    </List>
  );
};

export default CompoundIngredientsList;
