import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from '../styles';

const Collapse = ({ collapseHeader, children, isParent }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion expanded={expanded} sx={isParent ? styles.collapseParent : styles.collapseChild}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-expanded={expanded}
        aria-label="show more"
        sx={styles.accordionSummary}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? 'Hide ' : 'Show '}
        {collapseHeader}
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
};

Collapse.propTypes = {
  collapseHeader: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isParent: PropTypes.bool,
};

Collapse.defaultProps = {
  isParent: false,
};

export default Collapse;
