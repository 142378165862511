import React from 'react';
import PropTypes from 'prop-types';
import OtherAssignableVersion from './OtherAssignableVersion';
import Collapse from './Collapse';

const OtherAssignableVersions = ({ compoundIngredientId, otherAssignableVersions, success }) => {
  return (
    <Collapse collapseHeader="Other Assignable Versions" isParent>
      {otherAssignableVersions?.map((otherAssignableVersion) => {
        return (
          <OtherAssignableVersion
            key={otherAssignableVersion.id}
            otherAssignableVersion={otherAssignableVersion}
            compoundIngredientId={compoundIngredientId}
            success={success}
          />
        );
      })}
    </Collapse>
  );
};

OtherAssignableVersions.propTypes = {
  otherAssignableVersions: PropTypes.array.isRequired,
  success: PropTypes.bool.isRequired,
  compoundIngredientId: PropTypes.number.isRequired,
};

export default OtherAssignableVersions;
