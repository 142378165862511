/* eslint-disable no-restricted-globals */
import { Box, Button, Dialog, Typography } from '@mui/material';
import useLiveUpdate from 'hooks/useLiveUpdate';
import { broadcastReloadEvent } from 'lib/utils';
import React, { useEffect, useState } from 'react';

// eslint-disable-next-line react/prop-types
const DeployNotifier = ({ children }) => {
  const [open, setOpen] = useState();
  const onClose = () => {
    setOpen(false);
  };
  const deployData = useLiveUpdate('internal-apps', 'deploy-event');
  useEffect(
    () => {
      if (deployData) {
        setOpen(true);
      }
    },
    [deployData],
    setOpen
  );
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop
        PaperProps={{
          sx: {
            position: 'absolute',
            top: 16, // Adjust as needed
            right: 16, // Adjust as needed
            margin: 0,
            // zIndex: 90000,
          },
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography sx={{ m: 0, p: 0 }}>
            New version is available! <br />
            Do you want to update?
          </Typography>

          <span>
            <Button
              onClick={() => {
                broadcastReloadEvent();
              }}
            >
              Yes
            </Button>
            <Button onClick={onClose}>No</Button>
          </span>
        </Box>
      </Dialog>
      {children}
    </>
  );
};

export default DeployNotifier;
