// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { getSwapInIngredient } from 'redux/ingredientSwapper/swapInIngredient/actions';

// Components
import SearchField from './SearchField';
import styles from './styles';

const mapDispatchToProps = {
  onGetSwapInIngredient: getSwapInIngredient,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(SearchField));
