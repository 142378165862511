// Dependencies
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material Components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Helpers
import { validIngredientSearchInput } from 'lib/helpers/ingredientSwapper';

const SearchField = ({ onGetSwapInIngredient, classes }) => {
  const [queryParameters] = useSearchParams();
  const swapInIngredientId = queryParameters.get('swap-in') || null;

  // Lifecycle methods
  const [ingredientSearchInput, setIngredientSearchInput] = useState(swapInIngredientId || '');
  const [ingredientSearchInputError, setIngredientSearchInputError] = useState(false);
  const [ingredientSearchInputErrorMessage, setIngredientSearchInputErrorMessage] = useState('');

  useEffect(() => {
    if (swapInIngredientId) {
      onGetSwapInIngredient(swapInIngredientId);
    }
  }, [onGetSwapInIngredient, swapInIngredientId]);

  // Handler methods
  const handleSetIngredientSearchInput = (event) => {
    const { value } = event.target;
    const trimmedValue = value.trim();
    setIngredientSearchInput(trimmedValue);
    setIngredientSearchInputError(false);
    setIngredientSearchInputErrorMessage('');
  };

  const handleGetSwapInIngredient = () => {
    if (validIngredientSearchInput(ingredientSearchInput)) {
      onGetSwapInIngredient(ingredientSearchInput);
    } else {
      setIngredientSearchInputError(true);
      setIngredientSearchInputErrorMessage('Enter a valid Ingredient ID');
    }
  };

  const handleEnterIngredientSearchInput = (event) => {
    if (event.key === 'Enter') {
      handleGetSwapInIngredient();
    }
  };

  // Rendering
  return (
    <>
      <h3 className={classes.title}>1. Select New Ingredient</h3>
      <Grid container spacing={3}>
        <Grid item>
          <TextField
            id="target-ingredient-id"
            variant="outlined"
            label="Ingredient ID"
            size="small"
            color="secondary"
            value={ingredientSearchInput}
            error={ingredientSearchInputError}
            helperText={ingredientSearchInputErrorMessage}
            onChange={handleSetIngredientSearchInput}
            onKeyDown={handleEnterIngredientSearchInput} // Search by hitting the enter key
          />
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={handleGetSwapInIngredient}>
            Select
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

SearchField.propTypes = {
  onGetSwapInIngredient: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default SearchField;
