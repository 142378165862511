// Dependencies
import { connect } from 'react-redux';

// Components
import SwapInIngredientSelector from './SwapInIngredientSelector';

const mapStateToProps = (state) => ({
  swapInIngredient: state.ingredientSwapper.swapInIngredient,
  swapInIngredientError: state.ingredientSwapper.swapInIngredient.error,
});

export default connect(mapStateToProps, null)(SwapInIngredientSelector);
