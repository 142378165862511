import { defaultTheme } from 'react-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import { deepmerge } from '@mui/utils';
import { HC_GREEN, BACKGROUND_GREY, ERROR } from 'lib/constants';

export const hubAppTheme = deepmerge(defaultTheme, {
  palette: {
    primary: {
      main: HC_GREEN,
    },
    secondary: {
      main: HC_GREEN,
    },
    background: {
      paper: BACKGROUND_GREY,
    },
    error: {
      main: ERROR,
    },
  },
});
