import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Example: https://material-ui.com/components/text-fields/#FormattedInputs.js

// This is a component based on the react-number-format library that has been tuned for use in our app

const NumberFormatter = React.forwardRef((props, ref) => {
  const handleFocus = (event) => event.target.select();
  return (
    <NumberFormat
      allowNegative={false}
      className={props.className}
      decimalScale={2}
      defaultValue={0}
      disabled={props.disabled}
      fixedDecimalScale
      getInputRef={ref}
      name={props.name}
      onChange={props.onChange}
      onFocus={handleFocus}
      prefix="$"
      value={props.value}
      id={props.id}
    />
  );
});

NumberFormatter.propTypes = {
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  id: PropTypes.string.isRequired,
};

NumberFormatter.defaultProps = {
  value: 0,
};

NumberFormatter.displayName = 'NumberFormatter';

export default NumberFormatter;
