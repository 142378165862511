// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { resetErrorMessage } from 'redux/ingredientSwapper/swapOutIngredient/actions';

// Components
import SwapOutIdInput from './SwapOutIdInput';

import styles from './styles';

const mapStateToProps = (state) => ({
  errorMessage: state.ingredientSwapper.swapOutIngredient.errorMessage,
});

const mapDispatchToProps = {
  onResetErrorMessage: resetErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SwapOutIdInput));
