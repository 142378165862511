import { HC_GREEN, LIGHT_BLACK, LIGHT_GREEN, LIGHTEST_GREY, MEDIUM_GREY, RED } from 'lib/constants';

export default {
  accordionDetails: {
    fontSize: '14px',
    color: LIGHT_BLACK,
    minHeight: 'fit-content',
    margin: '0px !important',
    padding: 0,
    border: 'none',
    boxShadow: 'none',
  },
  accordionSummary: {
    textTransform: 'uppercase',
    color: HC_GREEN,
    fontSize: 14,
    fontWeight: '500',
    minHeight: '36px',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: LIGHT_GREEN,
    },
    '&:first-of-type': {
      '&:first-of-type .MuiAccordionSummary-content': {
        margin: '0px !important',
      },
    },
    '&.Mui-expanded': {
      minHeight: '36px',
      margin: '0px !important',
    },
    margin: '0px !important',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
      marginRight: '10px',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: '10px',
      color: HC_GREEN,
    },
  },
  claimsContainer: {
    borderTop: 'solid 1px #D8DADC',
    paddingTop: 24,
    marginTop: 24,
  },
  collapseChild: {
    '&.MuiPaper-root': {
      border: 'none',
      boxShadow: 'none',
    },
  },
  collapseParent: {
    '&.MuiPaper-root': {
      border: '1px solid green',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
  container: {
    padding: 24,
  },
  dialogActions: {
    padding: '16px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      width: 'auto',
    },
  },
  dialogContent: {
    overflow: 'auto',
    maxHeight: '400px',
  },
  dialogContentBoldText: {
    paddingTop: 2,
    paddingBottom: 2,
    fontWeight: 600,
  },
  dialogContentSubHeader: {
    paddingTop: 1,
    paddingBottom: 1,
    fontSize: 18,
    fontWeight: 600,
    color: HC_GREEN,
  },

  dialogContentText: {
    paddingTop: 2,
  },
  dialogHeader: {
    fontSize: 24,
    fontWeight: 600,
    paddingTop: 2,
    paddingBottom: 0,
  },
  errorBox: {
    backgroundColor: RED,
    color: LIGHTEST_GREY,
    padding: '12px',
  },
  labelText: {
    color: MEDIUM_GREY,
    fontSize: 10,
    paddingLeft: 12,
  },
  otherAssignableVersionHeader: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    color: LIGHT_BLACK,
  },
  otherAssignableVersionHeaderLink: {
    marginLeft: '10px',
    padding: '0px 5px',
    fontSize: '14px',
    fontWeight: '500',
    border: '1px solid green',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: LIGHT_GREEN,
    },
  },
  outputYieldGridItem: { marginTop: 8 },
  paddingRight: {
    paddingRight: 16,
  },
  paper: {
    padding: 16,
  },
  paperWithBottomMargin: {
    padding: 16,
    marginBottom: 16,
  },
  relatedMealsTab: {
    fontSize: '14px',
  },
  relatedMealsTabList: {
    height: '36px',
  },
  stepContent: {
    minWidth: '550px',
  },
  successBox: {
    backgroundColor: HC_GREEN,
    color: LIGHT_GREEN,
    padding: '12px',
  },
  tabPanel: {
    padding: '8px 16px',
  },
  text: {
    fontSize: 12,
  },
};
