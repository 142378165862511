import { APP_API_URI } from 'lib/constants';
import { GET } from 'lib/api';

export const fetchIngredient = async ({ swapInIngredientId }) => {
  const response = await GET(`${APP_API_URI}/internal/culinary/ingredient_swapper/ingredient`, {
    params: {
      ingredient_id: swapInIngredientId,
    },
  });

  return response.data;
};
