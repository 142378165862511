import {
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';
import MoneyInput from '../Common/MoneyInput';
import MealSelector from '../Common/MealSelector';

const TriggerSection = ({ filteredMeals, classes }) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);
  const [displayTargetValue, setDisplayTargetValue] = useState(true);
  const [displayEligibleUnit, setDisplayEligibleUnit] = useState(false);

  useEffect(() => {
    if (formState.targetValueCents) {
      setDisplayTargetValue(true);
      setDisplayEligibleUnit(false);
    } else if (formState.eligibleOrderableUnits) {
      setDisplayEligibleUnit(true);
      setDisplayTargetValue(false);
    }
  }, [formState.targetValueCents, formState.eligibleOrderableUnits]);

  const handleChange = (newType) => {
    if (newType === 'targetValueCents') {
      setDisplayTargetValue(true);
      setDisplayEligibleUnit(false);
      actions.setFormState('eligibleOrderableUnits', 0);
    } else if (newType === 'eligibleOrderableUnits') {
      setDisplayTargetValue(false);
      setDisplayEligibleUnit(true);
      actions.setFormState('targetValueCents', 0);
    }
  };

  return (
    <div className={classes.root}>
      <h6 className={classes.header}>Trigger</h6>
      <div className={classes.radioGroupContainer}>
        <FormControl>
          <FormLabel id="trigger-type-radio-buttons-group-label">Trigger Type</FormLabel>
          <RadioGroup
            aria-labelledby="trigger-type-radio-buttons-group-label"
            value={formState.triggerType}
            onChange={(e) => actions.setFormState('triggerType', e.target.value)}
          >
            <FormControlLabel value="basket" control={<Radio />} label="Basket Trigger" />
            <FormControlLabel
              value="meal"
              control={<Radio />}
              label="Meal Trigger"
              className={
                formState.discountDuration === 'recurring' ||
                (formState.lastMenuEndsAt && formState.lastMenuEndsAt !== formState.menuEndsAt)
                  ? classes.hidden
                  : classes.visible
              }
            />
          </RadioGroup>
        </FormControl>
      </div>
      <Divider className={classes.divider} />
      <MealSelector
        meals={filteredMeals}
        selectedMeals={formState.triggerMenuSelectionIds}
        selectedMealsCallback={(selectedMeals) =>
          actions.setFormState('triggerMenuSelectionIds', selectedMeals)
        }
        visible={formState.triggerType === 'meal'}
      />
      <Container className={classes.containerStyle} disableGutters>
        <Grid container spacing={2}>
          <Grid item sm={10} xs={8}>
            <div
              className={
                formState.triggerType === 'basket' && displayTargetValue
                  ? [classes.visible, classes.containerStyle]
                  : classes.hidden
              }
            >
              <MoneyInput
                fullWidth
                label="Minimum Basket Dollar Amount (Subtotal)"
                setFormError={(value) => actions.setFormValidation('errors', value)}
                helperText="This is the minimum order required to trigger the reward."
                disabled={formState.triggerType === 'meal'}
                isBasketTrigger
                focused={formState.targetValueCents >= 0}
                value={formState.targetValueCents}
              />
            </div>
            <div
              className={
                formState.triggerType === 'meal' || displayEligibleUnit
                  ? classes.visible
                  : classes.hidden
              }
            >
              <TextField
                fullWidth
                label="Number of Orderable Units to Trigger Reward"
                variant="outlined"
                value={formState.eligibleOrderableUnits}
                onChange={(e) => actions.setFormState('eligibleOrderableUnits', e.target.value)}
                helperText="This is the minimum order required to trigger the reward."
              />
            </div>
          </Grid>
          <Grid
            className={formState.triggerType === 'meal' ? classes.hidden : classes.visible}
            item
            sm={2}
            xs={4}
          >
            <ToggleButtonGroup
              color="primary"
              exclusive
              onChange={(e) => handleChange(e.target.value)}
              aria-label="Basket Trigger Type"
              size="large"
              sx={{ width: 1 }}
            >
              <ToggleButton
                value="targetValueCents"
                sx={{ width: 1 / 2 }}
                selected={displayTargetValue}
              >
                $
              </ToggleButton>
              <ToggleButton
                value="eligibleOrderableUnits"
                sx={{ width: 1 / 2 }}
                selected={displayEligibleUnit}
              >
                #
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

TriggerSection.defaultProps = {
  filteredMeals: [],
};

TriggerSection.propTypes = {
  classes: PropTypes.object.isRequired,
  filteredMeals: PropTypes.array,
};

export default TriggerSection;
