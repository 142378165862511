// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import {
  postSwapIngredient,
  getSwapOutIngredientMeals,
} from 'redux/ingredientSwapper/swapOutIngredient/actions';

// Components
import SwapOutIngredientMeals from './SwapOutIngredientMeals';

import styles from './styles';

const mapStateToProps = (state) => ({
  swapInIngredient: state.ingredientSwapper.swapInIngredient,
  swapOutIngredient: state.ingredientSwapper.swapOutIngredient,
});

const mapDispatchToProps = {
  onPostSwapIngredient: postSwapIngredient,
  onGetSwapOutIngredientMeals: getSwapOutIngredientMeals,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SwapOutIngredientMeals));
