import last from 'lodash/last';
import uniqBy from 'lodash/uniqBy';
import { v4 as uuid } from 'uuid';

import { sortByDisplayOrder } from 'lib/utils';
import { DEFAULT_MENU_LENGTH } from 'lib/constants/menugrid';

import { removePlaceholderPayload } from './beautifulMenugridDataConstructor';

export const removeDeletedMenuSelections = (selections) => {
  return selections?.filter((selection) => selection.deleted !== true);
};

export const getMenuLength = (menu) => {
  return Math.max(DEFAULT_MENU_LENGTH, last(menu.menuSelections)?.displayOrder);
};

export const createPlaceholder = ({ menuId, index, menuType }) => {
  return {
    /* placeholders do not reflect menu selections and are not persisted in the database,
    so they have no unique id.
    we are using the uuid package to create a unique id for each placeholder.
    we retain the same unique id for each placeholder for the length of the session.
    */
    id: uuid(),
    title: 'Placeholder',
    mealId: 0,
    displayOrder: index + 1,
    photo: '',
    primaryLabel: null,
    secondaryLabel: null,
    menuId,
    menuType,
  };
};

export const displayPlaceholdersOnMenu = (menu, menuType) => {
  const length =
    menuType !== 'staging' ? getMenuLength(menu) : last(menu.menuSelections)?.displayOrder;

  const countingArray = length > 0 ? [...new Array(length).keys()] : [];

  const placeholders = countingArray.map((el, i) => {
    return createPlaceholder({ menuId: menu.id, index: i, menuType });
  });

  const menuSelectionsWithPlaceholders = uniqBy(
    [...menu.menuSelections, ...placeholders],
    'displayOrder'
  ).sort(sortByDisplayOrder);

  const updatedMenu = menu;
  updatedMenu.menuSelections = menuSelectionsWithPlaceholders;
  return updatedMenu;
};

export const addPlaceholderToMenu = (menu, menuType) => {
  const placeholder = createPlaceholder({
    menuId: menu.id,
    index: menu.menuSelections.length,
    menuType,
  });

  const updatedMenu = menu;
  updatedMenu.menuSelections = [...menu.menuSelections, placeholder];

  return updatedMenu;
};

export const updatePlaceholder = (props) => {
  const { menuSelection, updatedMenuType, parentId, index } = props;
  const { displayOrder, menuId, menuType, ...unchanged } = menuSelection;

  if (menuSelection.mealId === 0) {
    if (menuSelection.deleted) return;
    // There seems to be no winning with this one. -KMV 6/3/22
    // eslint-disable-next-line consistent-return
    return {
      displayOrder: index + 1,
      menuId: parentId,
      menuType: updatedMenuType,
      ...unchanged,
    };
  }

  // eslint-disable-next-line consistent-return
  return menuSelection;
};

export const reorderPlaceholders = (selections, id, type) => {
  return selections.map((selection, i) => {
    return updatePlaceholder({
      menuSelection: selection,
      updatedMenuType: type,
      parentId: id,
      index: i,
    });
  });
};

export const reorderPlaceholdersOnChangedMenus = (menus, ids, menuType) => {
  return menus.map((menu) => {
    const { menuSelections, ...unchanged } = menu;
    const updatedSelections =
      ids.indexOf(menu.id) === -1
        ? menuSelections
        : reorderPlaceholders(menuSelections, menu.id, menuType);

    return {
      menuSelections: updatedSelections,
      ...unchanged,
    };
  });
};

export const getRemovePlaceholderChanges = (menu, menuType) => {
  return removePlaceholderPayload(menu, menuType);
};
