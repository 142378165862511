import React from 'react';
import PropTypes from 'prop-types';
import { DialogContentText } from '@mui/material';
import styles from '../styles';

const RelatedMeal = ({ relatedMeal }) => {
  return (
    <DialogContentText sx={styles.accordionDetails} key={relatedMeal.id} component="span">
      {relatedMeal.id} | {relatedMeal.title}
    </DialogContentText>
  );
};

RelatedMeal.propTypes = {
  relatedMeal: PropTypes.object.isRequired,
};

export default RelatedMeal;
