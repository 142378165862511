export const VOLUMETRIC_UNITS = ['volume', 'fluid_oz', 'tsp', 'tbsp', 'cup'];
export const EACHES = 'eaches';
export const PACK_OZ_WEIGHT = 'pack_oz_weight';
export const ASSIGNABLE_EVENTS = ['request_approval', 'approve_scaling', 'activate'];
export const EVENT_TYPES = [
  'archive',
  'request_approval',
  'request_review',
  'activate',
  'deactivate',
  'archive',
  'short_or_replace',
];
export const ARCHIVE_EVENT = 'archive';
