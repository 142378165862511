import {
  ISW_SWAP_IN_INGREDIENT,
  ISW_USAGE_YIELD,
  ISW_QUANTITY,
  ISW_MEASURE,
  ISW_PORTION_METHOD,
  ISW_INGREDIENT_PACKAGING_ID,
  ISW_SET_SWAPPABILITY,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { fetchIngredient } from './services';

export const getSwapInIngredient = (swapInIngredientId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: ISW_SWAP_IN_INGREDIENT,
        method: fetchIngredient,
        params: { swapInIngredientId },
      })
    );
  };
};

export const setUsageYield = (usageYield) => ({
  type: ISW_USAGE_YIELD,
  payload: { usageYield },
});

export const setQuantity = (quantity) => ({
  type: ISW_QUANTITY,
  payload: { quantity },
});

export const setMeasure = (measure) => ({
  type: ISW_MEASURE,
  payload: { measure },
});

export const setPortionMethod = (portionMethod) => ({
  type: ISW_PORTION_METHOD,
  payload: { portionMethod },
});

export const setIngredientPackagingId = (ingredientPackagingId) => ({
  type: ISW_INGREDIENT_PACKAGING_ID,
  payload: { ingredientPackagingId },
});

export const setSwappability = (isSwappable) => ({
  type: ISW_SET_SWAPPABILITY,
  payload: { isSwappable },
});
