import {
  ISW_SWAP_OUT_INGREDIENT,
  ISW_SWAP_OUT_INGREDIENT_MEALS,
  ISW_SWAP_INGREDIENT,
  ISW_RESET_ERROR_MESSAGE,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { fetchIngredient, swapIngredient } from './services';

export const getSwapOutIngredient = (swapOutIngredientId, showLiveMeals, showUnassignedMeals) => {
  console.log(swapOutIngredientId);
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: ISW_SWAP_OUT_INGREDIENT,
        method: fetchIngredient,
        params: { swapOutIngredientId, showLiveMeals, showUnassignedMeals },
      })
    );
  };
};

export const getSwapOutIngredientMeals = (
  swapOutIngredientId,
  showLiveMeals,
  showUnassignedMeals
) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: ISW_SWAP_OUT_INGREDIENT_MEALS,
        method: fetchIngredient,
        params: { swapOutIngredientId, showLiveMeals, showUnassignedMeals },
      })
    );
  };
};

export const postSwapIngredient = (swapOutIngredientAssignment, swapInIngredient) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: ISW_SWAP_INGREDIENT,
        method: swapIngredient,
        params: { swapOutIngredientAssignment, swapInIngredient },
      })
    );
  };
};

export const resetErrorMessage = () => ({
  type: ISW_RESET_ERROR_MESSAGE,
});
