import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Typography,
} from '@mui/material';
import { cleanArray } from 'lib/utils';
import OtherAssignableVersions from './OtherAssignableVersions';
import UpdateStateDirections from './UpdateStateDirections';
import styles from '../styles';

const UpdateStateDialog = ({
  open,
  onClose,
  compoundIngredientId,
  compoundIngredientDisplayName,
  otherAssignableVersions,
  postStateAction,
  eventType,
}) => {
  const { success } = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe
  );
  const successMessages = useSelector((state) =>
    cleanArray([state.compoundIngredientRecipeForm.compoundIngredientRecipe.successMessage])
  );
  const error = useSelector(
    (state) =>
      state.compoundIngredientRecipeForm.compoundIngredientRecipe.error ||
      state.compoundIngredientRecipeForm.formOptions.error
  );

  const errorMessages = useSelector((state) =>
    cleanArray([state.compoundIngredientRecipeForm.compoundIngredientRecipe.errorMessage])
  );

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (success) {
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
  }, [success]);

  const handleRequestApproval = () => {
    postStateAction(eventType);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        {success ? (
          <Box sx={styles.successBox}>
            {successMessages.map((message) => {
              return <Typography key={message}>Success! {message} </Typography>;
            })}
          </Box>
        ) : null}
        {error ? (
          <Box sx={styles.errorBox}>
            {errorMessages.map((message) => {
              return <Typography key={message}>Error! {message} </Typography>;
            })}
          </Box>
        ) : null}
        <DialogTitle sx={styles.dialogHeader}>Update Compound Ingredient State</DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <DialogContentText sx={styles.dialogContentSubHeader} component="span">
            Selected Compound Ingredient: {compoundIngredientId} -{' '}
            {upperFirst(compoundIngredientDisplayName)}
          </DialogContentText>
          <Stepper activeStep={activeStep} orientation="vertical" nonLinear sx={styles.stepper}>
            <Step completed={success}>
              <StepLabel sx={styles.stepLabel}>Legacy All Other Versions</StepLabel>
              <StepContent sx={styles.stepContent}>
                <UpdateStateDirections success={success} />
                <OtherAssignableVersions
                  otherAssignableVersions={otherAssignableVersions}
                  compoundIngredientId={compoundIngredientId}
                  success={success}
                />
                <DialogActions sx={styles.dialogActions}>
                  <Button onClick={() => handleCancel()} color="error" variant="outlined">
                    Exit With No State Changes
                  </Button>
                  <Button
                    onClick={() => handleRequestApproval()}
                    color="success"
                    variant="outlined"
                  >
                    Confirm All State Changes
                  </Button>
                </DialogActions>
              </StepContent>
            </Step>
            <Step>
              <StepLabel sx={styles.stepLabel}>Swap Out All Other Versions</StepLabel>
              <StepContent sx={styles.stepContent}>
                <UpdateStateDirections success />
                <OtherAssignableVersions
                  otherAssignableVersions={otherAssignableVersions}
                  compoundIngredientId={compoundIngredientId}
                  success={success}
                />
                <DialogActions sx={styles.dialogActions}>
                  <Button onClick={() => handleCancel()} color="error" variant="outlined">
                    Exit Without Swapping Ingredients
                  </Button>
                </DialogActions>
              </StepContent>
            </Step>
          </Stepper>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

UpdateStateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  compoundIngredientId: PropTypes.number.isRequired,
  compoundIngredientDisplayName: PropTypes.string.isRequired,
  otherAssignableVersions: PropTypes.array.isRequired,
  postStateAction: PropTypes.func.isRequired,
  eventType: PropTypes.string.isRequired,
};

export default UpdateStateDialog;
