import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styles from '../styles';
import RelatedMeal from './RelatedMeal';

const RelatedMealTabs = ({ relatedMeals }) => {
  const [value, setValue] = useState('upcoming');

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} sx={styles.relatedMealsTabList}>
          {Object.keys(relatedMeals).map((key) => {
            return (
              <Tab
                key={key}
                label={`${key} : ${
                  key === 'unassigned' ? relatedMeals[key] : relatedMeals[key].length
                }`}
                value={key}
                sx={styles.relatedMealsTab}
                disabled={key === 'unassigned'}
              />
            );
          })}
        </TabList>
      </Box>
      {Object.keys(relatedMeals).map((key) => {
        return (
          <TabPanel key={key} value={key} sx={styles.tabPanel}>
            {relatedMeals[key].length > 0
              ? relatedMeals[key].map((meal) => {
                  return <RelatedMeal key={meal.id} relatedMeal={meal} />;
                })
              : `No ${key} meals.`}
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

RelatedMealTabs.propTypes = {
  relatedMeals: PropTypes.object.isRequired,
};

export default RelatedMealTabs;
