import { BACKGROUND_GREY, HC_GREEN, WHITE, MATERIAL_OUTLINE_GRAY } from 'lib/constants';

export default {
  root: {
    backgroundColor: BACKGROUND_GREY,
  },
  container: {
    maxWidth: '1440px',
  },
  dialogActions: {
    padding: '16px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    '& button': {
      width: 'auto',
    },
  },
  dialogContent: {
    overflow: 'auto',
    maxHeight: '400px',
  },
  dialogContentBoldText: {
    paddingTop: 2,
    paddingBottom: 2,
    fontWeight: 600,
  },
  dialogContentSubHeader: {
    paddingTop: 1,
    paddingBottom: 1,
    fontSize: 18,
    fontWeight: 600,
    color: HC_GREEN,
  },

  dialogContentText: {
    paddingTop: 2,
  },
  dialogHeader: {
    fontSize: 24,
    fontWeight: 600,
    paddingTop: 2,
    paddingBottom: 0,
  },
  paper: {
    backgroundColor: WHITE,
    minHeight: '510px',
  },
  ingredientSelectorBox: {
    paddingTop: '16px',
    borderRight: `solid 1px ${MATERIAL_OUTLINE_GRAY}`,
    minHeight: '510px',
  },
  ingredientMealsBox: {
    paddingTop: '16px',
  },
};
