import { BORDER_GREY, HC_GREEN, LIGHT_BLACK, RED, MEDIUM_GREY, WHITE } from 'lib/constants';

export default {
  buttonSubmit: {
    backgroundColor: HC_GREEN,
    borderColor: HC_GREEN,
    color: WHITE,
    float: 'right',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    padding: '16px 48px',
    margin: '0 0 0 16px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: WHITE,
      color: HC_GREEN,
    },
  },
  container: {
    padding: '24px 0',
    width: '100%',
  },
  containerFixed: {
    backgroundColor: WHITE,
    boxShadow:
      '0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)',
    padding: '24px',
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
  },
  item: {
    borderRight: `1px solid ${BORDER_GREY}`,
    padding: '16px 24px',
    '&:last-of-type': {
      borderRight: '0px',
    },
  },
  text: {
    color: MEDIUM_GREY,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  textBold: {
    color: LIGHT_BLACK,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  textBoldRed: {
    color: RED,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  alert: {
    margin: '8px 0 8px 8px',
  },
};
