import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@mui/material/Grid';

const Header = ({ swapOutIngredient, classes }) => {
  return (
    <div>
      <h1 className={classes.name}>{swapOutIngredient.name}</h1>
      <h2 className={classes.brandName}>{swapOutIngredient.brandName}</h2>
      <Details swapOutIngredient={swapOutIngredient} classes={classes} />
    </div>
  );
};

const Details = ({ swapOutIngredient, classes }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      className={classes.details}
    >
      <Detail title="ID" value={swapOutIngredient.id} classes={classes} />
      <Detail title="Display Name" value={swapOutIngredient.displayName} classes={classes} />
      <Detail title="Standard Unit" value={swapOutIngredient.standardUnit} classes={classes} />
      <Detail title="Usage Yield" value={swapOutIngredient.usageYield} classes={classes} />
      <Detail title="State" value={swapOutIngredient.state} classes={classes} />
    </Grid>
  );
};

const Detail = ({ title, value, classes }) => {
  return (
    <Grid item>
      <p className={classes.detailTitle}>{title}</p>
      <p className={classes.detailValue}>{value}</p>
    </Grid>
  );
};

Header.propTypes = {
  swapOutIngredient: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

Details.propTypes = {
  swapOutIngredient: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

Detail.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  classes: PropTypes.object.isRequired,
};

export default Header;
