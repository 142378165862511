import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';

const useLiveUpdate = (channelName = 'internal-apps', eventName = 'deploy-event') => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const pusher = new Pusher('47f488b151ca13735f95', {
      cluster: 'us2',
    });

    const channel = pusher.subscribe(channelName);
    channel.bind(eventName, (backendData) => {
      setData(backendData);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return data;
};
export default useLiveUpdate;
