import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'react-admin';
import { HubAppBar } from './AppBar';
import { HubMenu } from './HubMenu';

const HubLayout = (props) => <Layout menu={HubMenu} {...props} appBar={HubAppBar} />;

HubLayout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default HubLayout;
