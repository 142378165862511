export const ACTIVE = 'active';
export const MEAL_KIT_AND_RTH_S_AND_R_ONLY = 'meal_kit_and_rth_s_and_r_only';
export const MEAL_KIT_ONLY = 'meal_kit_only';
export const NEEDS_APPROVAL = 'needs_approval';
export const RTH_AND_MEAL_KIT = 'rth_and_meal_kit';
export const RTH_ONLY = 'rth_only';
export const SHORTS_AND_REPLACEMENTS = 'shorts_and_replacements';
export const R_AND_D_READY = 'r&d_ready';
export const SCALING_WIP = 'scaling_wip';
export const DEPLETING = 'depleting';
