import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { broadcastScrollTopToParent, currentHeight } from 'lib/utils';
import { formOptionsShape } from 'lib/helpers/incidentManager/propShapes';

// Components
import { FeatureFlag } from 'components/shared';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

import { SessionContext } from 'contexts/sessionContext';
import AddIncidentButton from '../AddIncidentButton';
import CancelFields from '../CancelFields';
import DeliveryInfo from '../DeliveryInfo';
import Footer from '../Footer';
import MealsAndCharges from '../MealsAndCharges';
import IncidentSummary from '../IncidentSummary';
import IncidentForm from '../IncidentForm';

const IncidentGrid = ({
  cancelState,
  classes,
  incidentable,
  error,
  errorMessages,
  formOptions,
  formStates,
  success,
  successMessages,
  incidents,
  setCancelState,
  setFormStates,
  updateFormState,
  onCancelOrder,
  onGetFormOptions,
  onSubmitIncidents,
  onRaiseIncidentError,
}) => {
  const [buttonHeight, setButtonHeight] = useState(null);
  const { employeeId } = useContext(SessionContext);
  const renderErrorMessages = () => {
    return errorMessages.map((message) => {
      return (
        <Alert key={message} severity="error" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const renderSuccessMessages = () => {
    return successMessages.map((message) => {
      return (
        <Alert key={message} severity="success" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  useEffect(() => {
    if (formOptions.errorTypes === null) {
      onGetFormOptions(incidentable.id, incidentable.type);
    }
  }, [onGetFormOptions, formOptions, incidentable.id, incidentable.type]);

  const renderForms = () => {
    return Object.keys(formStates).map((formId) => {
      return (
        <IncidentForm
          key={formId}
          formId={formId}
          formOptions={formOptions}
          formStates={formStates}
          setFormStates={setFormStates}
          updateFormState={updateFormState}
        />
      );
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const arrayOfIncidentsParams = Object.values(formStates);

    const ingredientSelectionError = arrayOfIncidentsParams.some((incident) => {
      if (incident.incidentType === 'User Error') {
        return false;
      }

      const incidentCause = formOptions.errorTypes.find((cause) => cause.id === incident.causeId);

      return (
        incidentCause.ingredientSelectionRequired &&
        !incident.ingredientId &&
        !incident.ingredientAssignmentId
      );
    });

    if (ingredientSelectionError) {
      onRaiseIncidentError('You must select an ingredient for this error type.');
      return;
    }

    const incidentParams = {
      ...cancelState,
      employeeId,
      incidentableId: incidentable.id,
      incidentableType: incidentable.type,
      incidents: arrayOfIncidentsParams,
    };

    if (cancelState.cancel) {
      Promise.all([onSubmitIncidents(incidentParams), onCancelOrder(incidentParams)]);
    } else {
      onSubmitIncidents(incidentParams);
    }
  };

  const anyIncidents = incidents.length > 0;

  const findMaxHeight = () => {
    const addButtonPadding = 32;
    return currentHeight() - buttonHeight - addButtonPadding;
  };

  useEffect(() => {
    if (anyIncidents) {
      broadcastScrollTopToParent();
    }
  }, [anyIncidents]);

  return (
    <>
      {/* Here is an example of how to use FeatureFlag. */}
      <FeatureFlag flag="cs_beta_testing">
        <Alert key="cs_beta_testing" severity="warning" variant="filled">
          Testing Beta Features
        </Alert>
      </FeatureFlag>

      {success && renderSuccessMessages()}
      {error && renderErrorMessages()}

      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className={classes.incidentablesWrapper} style={{ maxHeight: findMaxHeight() }}>
              <DeliveryInfo incidentable={incidentable} />
              <MealsAndCharges incidentable={incidentable} />
            </div>
          </Grid>
          <Grid item xs={8}>
            {anyIncidents ? (
              <IncidentSummary />
            ) : (
              <form onSubmit={handleSubmit}>
                {renderForms()}
                <AddIncidentButton
                  formStates={formStates}
                  setFormStates={setFormStates}
                  setButtonHeight={setButtonHeight}
                />
                <CancelFields
                  cancelState={cancelState}
                  cancellable={incidentable.cancellable}
                  setCancelState={setCancelState}
                />
                <Footer incidentable={incidentable} formStates={formStates} />
              </form>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

IncidentGrid.propTypes = {
  cancelState: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  incidents: PropTypes.array,
  incidentable: PropTypes.object.isRequired,
  error: PropTypes.bool,
  errorMessages: PropTypes.array,
  formOptions: formOptionsShape,
  formStates: PropTypes.object.isRequired,
  success: PropTypes.bool,
  successMessages: PropTypes.array,
  setCancelState: PropTypes.func.isRequired,
  setFormStates: PropTypes.func.isRequired,
  onGetFormOptions: PropTypes.func.isRequired,
  updateFormState: PropTypes.func.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  onSubmitIncidents: PropTypes.func.isRequired,
  onRaiseIncidentError: PropTypes.func.isRequired,
};

IncidentGrid.defaultProps = {
  formOptions: {
    cancelReasons: null,
    errorTypes: null,
    userRemediationCategories: null,
    deliveryRemediationCategories: null,
  },
  error: false,
  errorMessages: [],
  incidents: [],
  success: false,
  successMessages: [],
};

export default IncidentGrid;
