// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { getSwapOutIngredient } from 'redux/ingredientSwapper/swapOutIngredient/actions';
import { getSwapInIngredient } from 'redux/ingredientSwapper/swapInIngredient/actions';

// Components
import IngredientSwapper from './IngredientSwapper';

import styles from './styles';

const mapStateToProps = (state) => ({
  swapOutIngredient: state.ingredientSwapper.swapOutIngredient,
  swapInIngredient: state.ingredientSwapper.swapInIngredient,
});
const mapDispatchToProps = {
  onGetSwapOutIngredient: getSwapOutIngredient,
  onGetSwapInIngredient: getSwapInIngredient,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IngredientSwapper));
