import { GET, PUT, POST, DELETE } from 'lib/api';
import { snakeCase } from 'lodash';

const dataProvider = (apiUrl) => ({
  getList: async (resource, params) => {
    const query = new URLSearchParams({
      ...params.filter,
      page: params.pagination.page,
      per_page: params.pagination.perPage,
      order: params.sort.order,
      sort: snakeCase(params.sort.field),
    });
    const url = `${apiUrl}/${resource}?${query.toString()}`;
    return GET(url).then((response) => {
      if (!response.headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }
      return {
        data: response.data,
        total: parseInt(response.headers.get('x-total-count').split('/').pop(), 10),
      };
    });
  },
  getOne: async (resource, params) => {
    let url = `${apiUrl}/${resource}/${params.id}`;
    if (params?.meta?.embed) {
      url += `?_embed=${params.meta.embed}`;
    }
    const { json } = await GET(url, { signal: params?.signal });
    return { data: json };
  },
  getMany: async (resource, params) => {
    const queryParams = {
      id: params.ids,
      _embed: params?.meta?.embed,
      signal: params?.signal,
    };
    const url = `${apiUrl}/${resource}`;
    const { json } = await GET(url, { params: queryParams });
    return { data: json };
  },
  create: async (resource, params) => {
    const { json } = await POST(`${apiUrl}/${resource}`, params.data);
    return { data: { ...params.data, ...json } };
  },
  update: async (resource, params) => {
    const { json } = await PUT(`${apiUrl}/${resource}/${params.id}`, params.data);
    return { data: json };
  },
  delete: async (resource, params) => {
    const { json } = await DELETE(`${apiUrl}/${resource}/${params.id}`);
    return { data: json };
  },
});

export default dataProvider;
