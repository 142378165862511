// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Material Components
import Grid from '@mui/material/Grid';

const SearchResults = ({ swapInIngredient, classes }) => {
  // Rendering
  return (
    <>
      <hr className={classes.hr} />
      <h4 className={classes.name}>{swapInIngredient.name}</h4>
      <h5 className={classes.brandName}>{swapInIngredient.brandName}</h5>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.details}
      >
        <Grid item xs={6}>
          <p className={classes.detailTitle}>Standard Unit</p>
          <p className={classes.detailValue}>{swapInIngredient.standardUnit}</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classes.detailTitle}>State</p>
          <p className={classes.detailValue}>{swapInIngredient.state}</p>
        </Grid>
      </Grid>
    </>
  );
};

SearchResults.propTypes = {
  swapInIngredient: PropTypes.shape({
    brandName: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    standardUnit: PropTypes.string,
    state: PropTypes.string,
    usageYield: PropTypes.string,
    adminIngredientLink: PropTypes.string,
    quantity: PropTypes.string,
    measure: PropTypes.string,
  }),
  classes: PropTypes.object.isRequired,
};

SearchResults.defaultProps = {
  swapInIngredient: null,
};

export default SearchResults;
